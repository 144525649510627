


























































































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/common/Header.vue';
import { generalDataService } from '@/services/GeneralDataService';

@Component({
  components: { Header },
})
export default class Reports extends Vue {
  data: any = [];
  groups: any = [];
  type = '';
  startMonth = '01';
  startYear = new Date().getFullYear() - 1;
  endMonth = '12';
  endYear = new Date().getFullYear() - 1;
  selectedGroup = 0;
  mounted() {
    this.type = this.$route.params.type || '';
    generalDataService
      .list('generalList/report')
      .then((data: any) => {
        this.data = data;
        if (data[0]) {
          this.type = data[0].id;
        }
      })
      .catch(() => {});

    generalDataService
      .list('generalList/structureGroup')
      .then((data: any) => {
        this.groups = data;
      })
      .catch(() => {});
  }
  viewReport() {
    this.$router
      .push(
        `/report/${this.type || 0}/${this.startYear}-${this.startMonth}/${this.endYear}-${this.endMonth}/${
          this.selectedGroup
        }`,
      )
      .catch(() => {});
  }
  goTo(path: string) {
    this.type = path;
    this.$router.push(`/reports/${path}`).catch(() => {});
  }
}
